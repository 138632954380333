<template>
   <div>
	  <b-card-body class="invoice-padding">
		 <validation-observer ref="observer"
							  v-slot="{handleSubmit,invalid}">
			<b-form
				ref="services-form"
				:style="{ height: trHeight }"
				class="repeater-form"
				@keyup="$emit('dirty')"
				@submit.prevent="repeateAgain"
			>
			   <!-- Row Loop -->
			   <div
				   v-for="(item, index) in form.services"
				   :id="item.id"
				   :key="item.id"
				   ref="row"
				   class="d-flex border rounded mb-1 shadow bg-white mx-2 repeater-row"
			   >
				  <b-row class="flex-grow-1 px-2 py-1 align-items-center">
					 <!-- Service -->
					 <b-col cols="12"
							md="5">
						<validation-provider v-slot="validationContext"
											 name="service"
											 rules="">
						   <b-form-group :state="getValidationState(validationContext)"
										 class="mb-1 mb-md-0"
										 label="Υπηρεσία"
										 label-for="demoServices">
							  <v-select
								  ref="servicesSelect"
								  v-model="item.title"
								  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								  :options="services"

								  :reduce="services => services.title"
								  append-to-body
								  input-id="demoServices"
								  label="title"
								  placehoder="Επιλέξτε..."
								  @input="onChangePrice($event,index)"
							  >
								 <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
								 <template #list-header>
									<li
										v-b-toggle.add-new-service
										class="add-new-client-header d-flex align-items-center text-success my-50"
										@click="newService.index = index"
									>
									   <feather-icon icon="PlusIcon"
													 size="16" />
									   <span class="align-middle ml-25">Προσθήκη νέας</span>
									</li>
								 </template>
							  </v-select>
						   </b-form-group>
						   <b-form-invalid-feedback id="form-title-lice-feedback">
							  {{ validationContext.errors[0] }}
						   </b-form-invalid-feedback>
						</validation-provider>
					 </b-col>

					 <!-- Τιμή -->
					 <b-col cols="6"
							md="2">
						<validation-provider v-slot="validationContext"
											 name="price"
											 rules=""
											 vid="price">
						   <b-form-group label="Τιμή"
										 label-for="service-price">

							  <currency-input v-model="item.price"
											  :disabled="!item.title"
											  :options="{currencyDisplay: 'hidden', currency: 'EUR',locale:'de-DE',precision:2 }"
											  class="form-control" />


							  <b-form-invalid-feedback id="form-name-lice-feedback">
								 {{ validationContext.errors[0] }}
							  </b-form-invalid-feedback>

						   </b-form-group>
						</validation-provider>
					 </b-col>

					 <!-- Date -->
					 <b-col cols="6"
							md="2">


						<b-form-group label="Ημερομηνία">
						   <date-picker
							   @input="(date) => handleChange(index, date)"

							   v-model="item.date"
							   :disabled="!item.title"

							   :show-time-header="true"
							   class="w-100"
							   format="DD-MM-YYYY"
							   type="date"
							   valueType="YYYY-MM-DD" />


						</b-form-group>

					 </b-col>

					 <b-col cols="6"
							lg="2">
						<label class="mb-1 mt-1 mt-md-0">Τελική τιμή</label>
						<strong class="mb-1 d-block text-primary">€{{ calculatePrice(item) }}</strong>
					 </b-col>
				  </b-row>

				  <div
					  class="d-flex flex-column justify-content-between border-left py-50 px-25"
				  >
					 <feather-icon
						 class="cursor-pointer"
						 icon="XIcon"
						 size="16"
						 @click="removeItem(index)"
					 />
					 <feather-icon
						 :id="`form-item-settings-icon-${index}`"
						 class="cursor-pointer"
						 icon="SettingsIcon"
						 size="16"
					 />

					 <!-- Setting Item Form -->
					 <b-popover
						 :ref="`form-item-settings-popover-${index}`"
						 v-click-outside="onClickOutside"
						 :target="`form-item-settings-icon-${index}`"
						 placement="lefttop"
						 triggers="click"
					 >

						<b-row>
						   <b-col cols="12">
							  <b-form-group label="Σημειώσεις"
											label-for="service-notes">
								 <b-form-textarea
									 id="service-notes"
									 v-model="item.notes"
									 :disabled="!item.title"
									 class="mb-2 mb-lg-0"
								 />
							  </b-form-group>
						   </b-col>
						   <!-- ΦΠΑ -->
						   <b-col cols="12">
							  <b-form-group label="Φ.Π.Α."
											label-for="greekVat">

								 <v-select
									 v-model="item.vat"
									 :disabled="!item.title"
									 :options="greekVat"
									 :reduce="vat => vat.value"
									 input-id="greekVat"
									 label="title"
									 placehoder="Επιλέξτε..."
								 />
							  </b-form-group>
						   </b-col>
						   <!-- Field: Discount -->
						   <b-col cols="12">
							  <b-form-group
								  :label-for="`setting-item-${index}-discount`"
								  label="Έκπτωση (%)"
							  >
								 <b-form-input
									 :id="`setting-item-${index}-discount`"
									 v-model="item.discount"
									 :disabled="!item.title"
									 :value="null"
									 type="number"
								 />
							  </b-form-group>
						   </b-col>

						   <b-col class="d-flex justify-content-between mt-1"
								  cols="12">
							  <b-button
								  class="d-none"
								  size="sm"
								  variant="outline-primary"
								  @click="
						                           () => {
						                             $refs[`form-item-settings-popover-${index}`][0].$emit(
						                               'close'
						                             );
						                           }
						                         "
							  >
								 Αποθήκευση
							  </b-button>
							  <b-button
								  size="sm"
								  variant="outline-secondary"
								  @click="
						                           () => {
						                             $refs[`form-item-settings-popover-${index}`][0].$emit(
						                               'close'
						                             );
						                           }
						                         "
							  >
								 Κλείσιμο
							  </b-button>
						   </b-col>
						</b-row>

					 </b-popover>
				  </div>
			   </div>
			</b-form>
		 </validation-observer>
		 <b-button
			 v-ripple.400="'rgba(255, 255, 255, 0.15)'"
			 class="mt-2 ml-2"
			 size="sm"
			 variant="outline-success"
			 @click="repeateAgain"
		 >
			<feather-icon class="mr-25"
						  icon="PlusIcon" />
			<span>{{ $t("generic.add") }}</span>
		 </b-button>
	  </b-card-body>


	  <b-sidebar
		  id="add-new-service"
		  :visible="false"
		  backdrop
		  bg-variant="white"
		  no-header
		  right
		  shadow
		  sidebar-class="sidebar-lg"
	  >
		 <template #default="{ hide }">
			<div
				class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
			>
			   <h5 class="mb-0">Προσθήκη Υπηρεσίας</h5>
			   <feather-icon class="ml-1 cursor-pointer"
							 icon="XIcon"
							 size="16" />
			</div>

			<b-form class="p-2"
					@submit.prevent="AddNewServices">

			   <b-form-group label="Υπηρεσία"
							 label-for="service-title">
				  <b-form-input id="service-title"

								v-model="newService.title"
								placeholder=""
								trim />
			   </b-form-group>

			   <b-form-group label="Τιμή"
							 label-for="service-ammount">

				  <currency-input v-model="newService.price"
								  :options="{currencyDisplay: 'hidden', currency: 'EUR',locale:'de-DE',precision:2 }"
								  class="form-control" />


				  <!--				  <input-->
				  <!--						 @focus="revertCurrencySideBar(newService)"-->
				  <!--						 @blur="convertCurrencySideBar(newService)"-->
				  <!--						 class="form-control"-->
				  <!--						 v-model="newService.price">-->


			   </b-form-group>

			   <b-form-group class="mt-2">
				  <b-form-checkbox
					  v-model="newService.isAdded"

					  value="Yes"
				  >
					 Να αποθηκευτεί στις Υπηρεσίες μου
				  </b-form-checkbox>
			   </b-form-group>

			   <!-- start::form actions -->
			   <div class="d-flex mt-4">
				  <b-button class="mr-2"
							type="submit"
							variant="primary"
							@click="hide">
					 Προσθήκη
				  </b-button>
				  <b-button type="reset"
							variant="outline-secondary"
							@click="hide">
					 Κλείσιμο
				  </b-button>
			   </div>
			   <!-- end::form actions -->
			</b-form>
		 </template>
	  </b-sidebar>


   </div>
</template>

<script>
import {
   BButton,
   BCard,
   BCardBody,
   BCol,
   BDropdown,
   BDropdownItem,
   BForm,
   BFormCheckbox,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BFormTextarea,
   BPagination,
   BPopover,
   BRow,
   BSidebar,
   BTable,
   VBToggle,
} from "bootstrap-vue";
import {heightTransition} from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import AddService from "@/views/caldo-global/AddService";
import axiosIns from "@/libs/axios";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {numeric, required} from 'vee-validate/dist/rules';
import FormMixin from "@/Mixins/FormMixin";
import ToastMixin from "@/Mixins/ToastMixin";
import _ from "lodash";
import store from "@/store";
import CurrencyInput from "@/views/components/Currency";
import vClickOutside from 'v-click-outside'
import { createPopper } from '@popperjs/core'
import moment from "moment";



extend('required', required);


extend('numeric', numeric);


export default {
   components: {
	  BButton,
	  CurrencyInput,
	  BForm,
	  BFormGroup,
	  BSidebar,
	  BFormCheckbox,
	  BFormInput,
	  BRow,
	  BCol,
	  BCard,
	  BCardBody,
	  BPopover,
	  vSelect,
	  flatPickr,
	  ValidationObserver, ValidationProvider,
	  BFormTextarea,
	  AddService,
	  BTable,
	  BDropdown,
	  BDropdownItem,
	  BFormInvalidFeedback,
	  BPagination
   },
   directives: {
	  Ripple,
	  "b-toggle": VBToggle,
	  clickOutside: vClickOutside.directive

   },

   mixins: [ToastMixin, FormMixin, heightTransition],

   props: {
	  form: Object
   },
   computed: {
	  options() {
		 return {
			distractionFree: false,
			valueAsInteger: true,
			autoDecimalMode: true
		 };
	  },

   },

   data() {
	  return {
		 newService: {
			title: null,
			price: null,
			isAdded: false,
			index: null
		 },

		 sum: 0,
		 services: [],
		 selectedVat: {title: "Χωρίς Φ.Π.Α."},
		 greekVat: [
			{value: 24, title: "Ναι"},
			{value: null, title: "Οχι"},
		 ],

	  };
   },

   async created() {
	  const response = await axiosIns.get(`/service`)
	  this.services = response.data
	  // document.getElementsByTagName('body')[0].addEventListener('click', e => {
	  //  this.$root.$emit('bv::hide::popover')
	  // });


	  window.addEventListener("resize", this.initTrHeight);
   },
   watch: {
	  form: {
		 handler() {
			// this.form.services.forEach((el, idx) => this.convertCurrency(idx))
		 },
	  },
   },
   async mounted() {

	  // this.form.services.forEach((el, idx) => this.convertCurrency(idx))

	  // this.$refs.price
   },

   destroyed() {
	  window.removeEventListener("resize", this.initTrHeight);
   },

   methods: {

	  withPopper(dropdownList, component, { width }) {
		 /**
		  * We need to explicitly define the dropdown width since
		  * it is usually inherited from the parent with CSS.
		  */
		 dropdownList.style.width = width

		 /**
		  * Here we position the dropdownList relative to the $refs.toggle Element.
		  *
		  * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
		  * the dropdownList overlap by 1 pixel.
		  *
		  * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
		  * wrapper so that we can set some styles for when the dropdown is placed
		  * above.
		  */
		 const popper = createPopper(component.$refs.toggle, dropdownList, {
			placement: this.placement,
			modifiers: [
			   {
				  name: 'offset',
				  options: {
					 offset: [0, -1],
				  },
			   },
			   {
				  name: 'toggleClass',
				  enabled: true,
				  phase: 'write',
				  fn({ state }) {
					 component.$el.classList.toggle(
						 'drop-up',
						 state.placement === 'top'
					 )
				  },
			   },
			],
		 })

		 /**
		  * To prevent memory leaks Popper needs to be destroyed.
		  * If you return function, it will be called just before dropdown is removed from DOM.
		  */
		 return () => popper.destroy()
	  },


	  onClickOutside() {

	  },
	  handleChange(index, date) {
		 this.form.services[index].date = date
		 this.form.services.push({price: "0,00"});
		 this.form.services.splice(this.form.services.length-1, 1);
		 // this.$set(this.form.services, index, date)
	  },
	  async onChangePrice(e, index) {

		 const {data} = await axiosIns.get('/service')
		 const price = _.find(data, {title: e})?.price;
		 if (price) {
			this.form.services[index].price = price
		 } else {
			this.form.services[index].price = _.find(this.services, {title: e}).price;
		 }
		 this.form.services[index].date =  moment().format('YYYY-MM-DD');
		 this.$emit('dirty')
	  },


	  parseLocaleNumber(stringNumber, locale) {
		 var thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
		 var decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

		 return parseFloat(stringNumber
			 .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
			 .replace(new RegExp('\\' + decimalSeparator), '.')
		 );
	  },
	  calculatePrice(index) {


		 let finalPrice = index.price;
		 if (index?.discount) {
			finalPrice = finalPrice - (finalPrice * index.discount / 100)
		 }
		 if (index?.vat) {
			finalPrice = finalPrice * 1.24
		 }


		 return new Intl.NumberFormat('de-DE', {
			style: 'currency',
			currency: 'EUR',
			currencyDisplay: "code"
		 }).format(finalPrice)
			 .replace("EUR", "")
			 .trim();


	  },
	  // formatPrice(value) {
	  //  let val = (value / 1).toFixed(2).replace('.', ',')
	  //  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
	  // },


	  async AddNewServices(e) {
		 if (this.newService.isAdded) {
			const services =
				{
				   "title": this.newService.title,
				   "price": parseInt(this.newService.price),
				}

			await store.dispatch('storeSingleSettings', services)

		 }
		 this.services.push(_.cloneDeep(this.newService))

		 this.form.services[this.newService.index].title = this.newService.title
		 this.form.services[this.newService.index].price = this.newService.price

		 this.form.services[this.newService.index].date = moment().format('YYYY-MM-DD')
		 this.resetField(this.newService);


		 this.$emit('dirty')
		 this.notify("Δημιουργήθηκε")
	  },

	  repeateAgain() {
		 this.form.services.push({price: "0,00"});


	  },
	  getValidationState({dirty, validated, valid = null}) {

		 return validated ? valid : null;
	  },
	  removeItem(index) {
		 this.form.services.splice(index, 1);
		 this.trTrimHeight(this.$refs.row[0].offsetHeight);
	  },
	  initTrHeight() {
		 this.trSetHeight(null);
	  },
   },
};
</script>

<style lang="scss"
	   scoped>
.repeater-form {
   overflow: hidden;
   transition: 0.35s height;
}
</style>

<style lang="scss"
	   scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/libs/vue-flatpicker.scss";


.add-new-client-header {
   padding: 0.5rem 1rem;
}


.add-new-client-header:hover {
   background-color: rgba(40, 199, 111, 0.12);
}


.invoice-padding {
   padding: 0 0 1.5rem 0;
}
.v-select.drop-up.vs--open .vs__dropdown-toggle {
   border-radius: 0 0 4px 4px;
   border-top-color: transparent;
   border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
   border-radius: 4px 4px 0 0;
   border-top-style: solid;
   border-bottom-style: none;
   box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}

.repeater-row .form-group {
   margin-bottom: 0;
}


.form-item-section {
   background-color: $product-details-bg;
}


.form-item-action-col {
   width: 27px;
}


.repeater-form {
   // overflow: hidden;
   transition: 0.35s height;
}


.repeater-row {
   .dark-layout & {
	  background: $theme-dark-body-bg !important;
   }
}


.v-select {
   &.item-selector-title,
   &.payment-selector {
	  background-color: #fff;

	  .dark-layout & {
		 background-color: unset;
	  }
   }
}


.dark-layout {
   .form-item-section {
	  background-color: $theme-dark-body-bg !important;

	  .row .border {
		 background-color: $theme-dark-card-bg;
	  }
   }
}
</style>
