<template>
   <div class="row">
	  <div class="col-md-7">
		 <b-table
			 id="contacts-table"
			 :current-page="filters.currentPage"
			 :empty-text="$t('errors.no-entries-found')"
			 :fields="tableColumns"
			 :items="getFile.data"
			 :perPage="0"
			 class="position-relative"
			 no-local-sorting
			 primary-key="id"
			 responsive
			 show-empty
		 >

			<!-- Column: filename -->
			<template #cell(filename)="data">
			   {{ getName(data) }}
			</template>
			<!-- Column: ext -->
			<template #cell(ext)="data">
			   {{ getExt(data) }}
			</template>
			<!-- Column: ext -->
			<template #cell(actions)="data">
			   <feather-icon
				   :id="`invoice-row-${data.item.id}-preview-icon`"
				   class="cursor-pointer ml-1"
				   icon="EyeIcon"
				   size="16"
				   @click="previewFile(data)"
			   />
			   <!--			   <b-tooltip-->
			   <!--				   :title="$t('generic.view')"-->
			   <!--				   :target="`invoice-row&#45;&#45;preview-icon`"-->
			   <!--			   />-->
			   <feather-icon
				   :id="`invoice-row-${data.item.id}-preview-icon`"
				   class="cursor-pointer ml-1"
				   icon="DownloadIcon"
				   size="16"
				   @click="downloadFile(data)"
			   />
			   <!--			   <b-tooltip-->
			   <!--				   :title="$t('generic.view')"-->
			   <!--				   :target="`invoice-row&#45;&#45;preview-icon`"-->
			   <!--			   />-->
			   <feather-icon
				   :id="`invoice-row-${data.item.id}-preview-icon`"
				   class="cursor-pointer ml-1"
				   icon="DeleteIcon"
				   size="16"
				   @click="deleteFile(data)"
			   />
			   <!--			   <b-tooltip-->
			   <!--				   :title="$t('generic.view')"-->
			   <!--				   :target="`invoice-row&#45;&#45;preview-icon`"-->
			   <!--			   />-->
			</template>


		 </b-table>
	  </div>
	  <div class="col-md-5 p-2">
		 <vue-dropzone id="myVueDropzone"
					   :options="dropzoneOptions"
					   :useCustomSlot=true
					   class="bg-transparent"
					   v-on:vdropzone-sending="sendingEvent"
					   v-on:vdropzone-success="onSuccess">
			<div class="dropzone-custom-content">
			   <h3 class="dropzone-custom-title">Σύρετε και αποθέστε για να ανεβάσετε περιεχόμενο!!</h3>
			   <div class="subtitle">...ή κάντε κλικ για να επιλέξετε ένα αρχείο από τον υπολογιστή σας</div>
			</div>
		 </vue-dropzone>
	  </div>
   </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import axiosIns from "@/libs/axios";
import {BPagination, BTable} from "bootstrap-vue";
import ToastMixin from "@/Mixins/ToastMixin";
import caldoSettings from "@/caldoSettings";

export default {
   name: 'app',
   components: {
	  vueDropzone: vue2Dropzone,
	  BTable,
	  BPagination,
   },
   mixins: [ToastMixin],
   data: function () {
	  return {
		 tableColumns: [
			{key: 'id', label: 'Id', sortable: true},
			{key: 'filename', label: 'Ονομα', sortable: true},
			{key: 'ext', label: 'Επέκταση', sortable: true},
			{key: 'actions', label: 'Ενέργειες', sortable: false},

		 ],
		 filters: {
			currentPage: 1,
			selectedPageOptions: 6,
		 },
		 getFile: [],
		 dropzoneOptions: {
			addRemoveLinks: true,
			// url: 'http://demo1.caldo.local/api/project-file',
			url: `${caldoSettings.baseUrl}/project-file`,
			thumbnailWidth: 150,
			headers: {Authorization: `Bearer ${localStorage.getItem('access_token')}`},

		 }
	  }
   },
   methods: {
	  sendingEvent(file, xhr, formData) {
		 // formData.append('projectId', this.$route.params.projectId);
		 formData.append('projectId', this.$route.params.projectId);
	  },
	  async onSuccess(file, response) {
		 this.$emit('dirty');
		 this.getFile = await axiosIns.get(`project-files/${this.$route.params.projectId}`);
	  },
	  previewFile(data) {
		 window.open(data.item.filename, 'name', 'noopener=yes');

	  },
	  getName(data) {
		 return data.item.filename.split("/").pop().split(".").shift().split("_").slice(1).join()
	  },
	  getExt(data) {
		 return data.item.filename.split("/").pop().split(".").pop()
	  },
	  async deleteFile(data) {
		 const {isConfirmed} = await this.swal()
		 if (isConfirmed) {
			await axiosIns.delete(`project-file/${data.item.id}`);

		 }
		 this.getFile = await axiosIns.get(`project-files/${this.$route.params.projectId}`);
	  },
	  async downloadFile(data) {
		 const fileName = data.item.filename;

		 try {
			const response = await fetch(fileName, {})
			const blob = await response.blob();
			const url = URL.createObjectURL(blob)
			const a = document.createElement("a");
			a.href = url;
			a.download = `${this.getName(data)}.${this.getExt(data)}`;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		 } catch (err) {

		 }
	  }
   },
   async created() {
	  this.getFile = await axiosIns.get(`project-files/${this.$route.params.projectId}`);
   }
}
</script>
<style>


</style>