<template>

   <b-card no-body>
	  <b-card-body>

		 <b-row>
			<b-col cols="12"
				   md="9">
			   <h4 class="mb-50">
				  {{ form.title }}
			   </h4>
			   <span class="card-text">
              <span class="mr-1">
                  <span class="text-primary font-weight-bolder">#</span>
                  {{ form.id }}
              </span>
              <b-badge v-if="category"
					   variant="light-warning">
				 {{ category }}
              </b-badge>
				 <span class="d-none">
				 {{ getCategory }}
				 </span>
          </span>
			</b-col>
			<b-col class="text-right"
				   cols="12"
				   md="3">
			   <b-button
				   v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				   size="sm"
				   variant="outline-danger"
				   @click="deleteProject"
			   >
				  <feather-icon
					  class="mr-50"
					  icon="TrashIcon"
				  />
				  <span class="align-middle">{{ $t('generic.delete') }}</span>
			   </b-button>
			</b-col>

			<b-col class="mt-2 mb-1">
			   <div class="project-info">
				  <div class="item">
					 <h6 class="section-label">
						{{ $t('tables.uclient') }}
					 </h6>
					 <h6 class="mb-0">
						<b-link :to="{ name: 'contacts-view', params: { userId:$store.getters.getContact.id } }"
								class="font-weight-bold d-block text-nowrap">
						   {{ $store.getters.getContact.surname }} {{ $store.getters.getContact.name }}
						</b-link>
					 </h6>
				  </div>
				  <div class="item">
					 <h6 class="section-label">
						{{ $t('projects.start-date') }}
					 </h6>
					 <h6 class="mb-0">
						{{ startDate }}
					 </h6>
				  </div>
				  <div class="item">
					 <h6 class="section-label">
						{{ $t('projects.end-date') }}
					 </h6>
					 <h6 v-if="endDate"
						 class="mb-0">
						{{ endDate }}
					 </h6>
					 <h6 v-else
						 class="mb-0">
						-
					 </h6>
				  </div>
				  <div class="item">
					 <h6 class="section-label">
						{{ $t('projects.works') }} ({{ totalTask }})
					 </h6>
					 <h6 class="mb-0">
						{{ completedTask }} {{ $t('projects.completed') }}
					 </h6>
				  </div>
			   </div>
			</b-col>
		 </b-row>

		 <b-row>
			<b-col cols="12"
				   md="5">
			   <h6 class="section-label">
				  {{ $t('projects.coworking') }}
			   </h6>
			   <div class="d-flex align-items-center">
				  <b-avatar-group class="mb-2 mb-md-0"
								  size="32px"
								  style="flex: 1">
					 <b-avatar
						 v-for="partner in getPartners.slice(0,5)"
						 v-if="partner"
						 :key="partner.id"
						 v-b-tooltip.hover
						 :src="partner.photo"
						 :text="initials(partner)?initials(partner):''"
						 :title="`${partner.name} ${partner.surname}`"
						 class="pull-up"
					 />

				  </b-avatar-group>
				  <!-- Show if more than 6 -->

				  <div v-show="getPartners.slice(5,50).length"
					   class="ml-1 cursor-pointer"
					   name="myTrigger"
					   style="flex: 1">+ {{ getPartners.slice(5, 50).length }} {{ $t('projects.more') }}
				  </div>
				  <!-- Show if more than 6 -->
				  <tippy arrow
						 to="myTrigger">
					 <div>

						<div
							v-for="partner in getPartners.slice(5,50)"
							:key="partner.id"
						>
						   {{ `${partner.name} ${partner.surname}` }}
						</div>


					 </div>
				  </tippy>
			   </div>
			</b-col>
			<b-col cols="12"
				   md="7">
			   <div>
				  <h6 class="section-label mb-10 mb-md-2">{{ $t('projects.completition') }}</h6>
				  <b-progress
					  :max="max"
					  class="progress-bar-secondary"
				  >
					 <b-progress-bar
						 :label="`${((form.tasks.filter(el=> el.status ==='Ολοκληρωμένο').length / form.tasks.length *100 / max) * 100).toFixed(2)}%`"
						 :value="form.tasks.filter(el=> el.status ==='Ολοκληρωμένο').length / form.tasks.length *100"
						 variant="secondary"
					 />
				  </b-progress>
			   </div>
			</b-col>
		 </b-row>

	  </b-card-body>

   </b-card>


</template>

<script>
import {
   BAvatar,
   BAvatarGroup,
   BBadge,
   BButton,
   BCard,
   BCardBody,
   BCardHeader,
   BCol,
   BLink,
   BProgress,
   BProgressBar,
   BRow,
   VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from "moment";
import ToastMixin from "@/Mixins/ToastMixin";
import FormMixin from "@/Mixins/FormMixin";


export default {
   components: {
	  BRow,
	  BCol,
	  BCard,
	  BCardHeader,
	  BCardBody,
	  BLink,
	  BBadge,
	  BButton,
	  BAvatar,
	  BAvatarGroup,
	  BProgress,
	  BProgressBar
   },
   props: {
	  form: Object,
	  allProjects: Array,
   },
   mixins: [ToastMixin, FormMixin],
   data() {
	  return {
		 category: '',
		 max: 100,
		 value: 0,
	  }
   },
   computed: {
	  async getCategory() {
		 const category = _.find(this.allProjects, {id: this.form.categoryId})?.title;
		 this.category = category;
		 return category;
	  },
	  async getContact() {

		 return this.$store.state.contact
	  },
	  startDate() {
		 if (this.form.startDate)
			return moment(this.form.startDate).lang('el').format('DD MMM YYYY')
		 return ' '
	  },
	  endDate() {
		 if (this.form.deadline)
			return moment(this.form.deadline).lang('el').format('DD MMM YYYY')
		 return false
	  },
	  totalTask() {
		 var filtered = this.form.tasks.filter(element => {
			if (Object.keys(element).length !== 0) {
			   return true;
			}
		 })
		 filtered = filtered.map((el, index) => {

			if (!el.hasOwnProperty('title')) {
			   return {}
			}
			return this.removeEmpty(el);
		 })
		 filtered = filtered.filter(
			 obj => !(obj && Object.keys(obj).length === 0)
		 );

		 return filtered.length;


	  },
	  completedTask() {
		 return this.form.tasks.filter(el => el.status === 'Ολοκληρωμένο').length
	  },
	  getPartners() {
		 let allPartners = this.form.tasks.map(el => el.partners);

		 return _.uniqBy(_.flatten(allPartners), 'id')
	  },


   },


   methods: {
	  getColor(color) {
		 const colors = {
			'#7367f0': "light-primary",
			'#82868b': "light-secondary",
			'#28c76f': "light-success",
			'#ff9f43': "light-warning",
			'#ea5455': "light-danger",
			'#00cfe8': "light-info",
		 }
		 return colors[color];
	  },
	  initials(fullName) {
		 return fullName?.name?.charAt(0) + fullName?.surname?.charAt(0);
	  },
	  async deleteProject() {

		 const {isConfirmed} = await this.swal()
		 if (isConfirmed) {
			await this.$store.dispatch('deleteProject', this.$route.params.projectId)

			this.notify('Διαγράφηκε', `danger`)

			this.$router.push('/apps/projects/')
		 }

	  },
   },

   async mounted() {

	  // await this.$store.dispatch('fetchContact', this.$route.params.userId)
   },


   directives: {
	  Ripple,
	  'b-tooltip': VBTooltip,
   },
}
</script>

<style lang="scss"
	   scoped>
.project-info {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
}


.project-info .item {
   margin-bottom: .7rem;
   text-align: left;
   border-right: 1px solid #ccc;
   padding-right: 25px;
   margin-right: 25px;
}


.project-info .item:last-child {
   border-right: none;
   padding-right: 0;
   margin-right: 0;
}


@media screen and (max-width: 480px) {
   .project-info .item {
	  width: 50%;
	  border-right: none;
	  padding-right: 0;
	  margin-right: 0;
   }
}
</style>