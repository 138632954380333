<template>
   <b-sidebar
	   id="sidebar-expenses"
	   :visible="false"
	   backdrop
	   bg-variant="white"
	   no-header
	   right
	   shadow
	   sidebar-class="sidebar-lg"
   >
	  <template #default="{ hide }">
		 <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
			<h5 class="mb-0">
			   {{ showPaymentEdit ? $t('projects.edit-payment') : $t('projects.add-payment') }}
			</h5>
			<feather-icon
				class="ml-1 cursor-pointer"
				icon="XIcon"
				size="16"

			/>
		 </div>
		 <validation-observer ref="observer"
							  v-slot="{ handleSubmit, invalid }">
			<b-form class="p-2"
					@submit.prevent="onSubmitPayments">
			   <!-- start::select title -->
			   <validation-provider
				   v-slot="validationContext"
				   :rules="{ required: true }"
			   >
				  <b-form-group
					  :label="$t('generic.title')"
					  class="required"
					  label-for="expence-title"
				  >
					 <b-form-input
						 id="expence-title"
						 v-model="pay.title"
						 :state="getValidationState(validationContext)"
						 placeholder=""
					 />
					 <b-form-invalid-feedback v-if="validationContext.errors[0]">
						{{ $t(getError(validationContext.errors[0])) }}
					 </b-form-invalid-feedback>
				  </b-form-group>
			   </validation-provider>
			   <!-- end::select title -->

			   <!-- start::select amount -->
			   <b-row>
				  <b-col md="6">
					 <validation-provider
						 v-slot="validationContext"
						 :rules="{ required: true }"
					 >
						<!-- start::ammount -->
						<b-form-group
							:label="$t('generic.ammount')"
							label-for="expence-ammount"
						>
						   <currency-input
							   ref="amountRef"
							   v-model="pay.amount"
							   :options="{
                      currencyDisplay: 'hidden',
                      currency: 'EUR',
                      locale: 'de-DE',
                      precision: 2,
                    }"
							   :state="getValidationState(validationContext)"
							   class="form-control"
						   />
						   <b-form-invalid-feedback v-if="validationContext.errors[0]">
							  {{ $t(getError(validationContext.errors[0])) }}
						   </b-form-invalid-feedback>
						</b-form-group>
					 </validation-provider>
					 <!-- end::ammount -->
				  </b-col>
				  <b-col md="6">
					 <!-- start::expence-date -->

					 <b-form-group
						 :label="$t('generic.date')"
						 label-for="expence-date"
					 >

						<date-picker
							v-model="pay.datestamp"
							:show-time-header="true"
							class="w-100"
							format="DD-MM-YYYY"
							type="date"
							valueType="YYYY-MM-DD" />
						<span v-if="!pay.datestamp"
							  class="invalid-feedback"
						>Υποχρεωτικό πεδίο</span
						>
					 </b-form-group>

					 <!-- end::expence-date -->
				  </b-col>
			   </b-row>
			   <!-- end::select amount -->

			   <!-- start::expence-repeating -->
			   <b-form-group>
				  <b-form-checkbox v-model="pay.repeatable">
					 {{ $t("generic.repeating") }}
				  </b-form-checkbox>
			   </b-form-group>
			   <!-- end::expence-repeating -->

			   <!-- start::expence-end-date -->
			   <div v-if="pay.repeatable"
					class="bg-light p-1 rounded mb-1">
				  <validation-provider
					  v-slot="validationContext"
					  rules="required_if:form.repeatable,true"
				  >
					 <b-form-group :label="$t('generic.repeat-each')">
						<b-form-radio-group
							v-model="pay.interval"
							:options="interval"
							:state="getValidationState(validationContext)"
						/>
						<b-form-invalid-feedback v-if="validationContext.errors[0]">
						   {{ $t(getError(validationContext.errors[0])) }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>

				  <b-form-group
					  :label="$t('generic.until')"
					  label-for="expence-end-date"
				  >
					 <date-picker
						 v-model="pay.repeatableUntil"
						 :show-time-header="true"
						 class="w-100"
						 format="DD-MM-YYYY"
						 type="date"
						 valueType="YYYY-MM-DD" />
				  </b-form-group>
			   </div>
			   <!-- end::expence-end-date -->

			   <!-- start::expence category -->
			   <!-- Τις φέρνει από τις Ρυθμίσεις -->
			   <b-form-group
				   :label="$t('contacts.category')"
				   label-for="expence-category"
			   >
				  <v-select
					  v-model="pay.categoryId"
					  :options="$store.getters.getSettingExpense"
					  :reduce="(item) => item.id"
					  label="title"
					  placehoder="Επιλέξτε..."
				  >
					 <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
				  </v-select>
			   </b-form-group>
			   <!-- end::expence category -->




			   <!-- start::select payment method -->
			   <validation-provider
				   v-slot="validationContext"
				   :rules="{ required: false }"
			   >
				  <b-form-group
					  :label="$t('generic.payment-method')"
					  label-for="payment-method"
				  >
					 <v-select
						 v-model="pay.paymentMethod"
						 :options="getPayment"
						 :reduce="(val) => val.value"
						 :state="getValidationState(validationContext)"
						 label="text"
						 placehoder="Επιλέξτε..."
					 >
						<template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
					 </v-select>
					 <b-form-invalid-feedback>
						{{ validationContext.errors[0] }}
					 </b-form-invalid-feedback>
				  </b-form-group>
			   </validation-provider>
			   <!-- end::select payment method -->

			   <!-- start:notes -->
			   <b-form-group :label="$t('contacts.notes')"
							 label-for="expence-notes">
				  <b-form-textarea
					  v-model="pay.notes"
					  :options="editorOption"
				  />
				  <div
					  id="quill-toolbar"
					  class="d-flex justify-content-end border-top-0"
				  ></div>
			   </b-form-group>
			   <!-- end:notes -->

			   <!-- start::form actions -->
			   <div class="d-flex mt-2">
				  <b-button class="mr-2"
							:disabled="invalid || !pay.datestamp"
							type="submit"
							variant="primary">
					 {{ $t("generic.save") }}


				  </b-button>
				  <b-button
					  variant="outline-secondary"
					  @click="hide">
					 {{ $t("generic.cancel1") }}
				  </b-button>
			   </div>
			   <!-- end::form actions -->

			</b-form>
		 </validation-observer>
	  </template>
   </b-sidebar>
</template>

<script>
import {
   BAvatar,
   BButton,
   BForm,
   BCol,
   BFormRadioGroup,
   BFormCheckbox,
   BFormGroup,
   BFormInput,
   BRow,
   BFormInvalidFeedback,
   BFormTextarea,
   BSidebar
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import FormMixin from "@/Mixins/FormMixin";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required, required_if} from "vee-validate/dist/rules";
import ToastMixin from "@/Mixins/ToastMixin";
import _ from "lodash";
import {Money} from "v-money";
import caldoSettings from "@/caldoSettings";
import moment from "moment";
import CurrencyInput from "@/views/components/Currency";

const guestsOptions = [
   {avatar: require('@/assets/images/avatars/1-small.png'), name: 'Jane Foster'},
   {avatar: require('@/assets/images/avatars/3-small.png'), name: 'Donna Frank'},
   {avatar: require('@/assets/images/avatars/5-small.png'), name: 'Gabrielle Robertson'},
   {avatar: require('@/assets/images/avatars/7-small.png'), name: 'Lori Spears'},
   {avatar: require('@/assets/images/avatars/9-small.png'), name: 'Sandy Vega'},
   {avatar: require('@/assets/images/avatars/11-small.png'), name: 'Cheryl May'},
]

extend("required", {
   ...required,
   message: "Υποχρεωτικό πεδίο",
});
extend("required_if", {
   ...required_if,
   message: "Υποχρεωτικό πεδίο",
});

export default {
   components: {
	  BSidebar,
	  CurrencyInput,
	  BForm,
	  Money,
	  BFormGroup,
	  BFormInput,
	  BFormInvalidFeedback,
	  vSelect,
	  ValidationObserver,
	  ValidationProvider,
	  BCol,
	  BRow,
	  BFormRadioGroup,
	  BFormCheckbox,
	  BAvatar,
	  BFormTextarea,
	  BButton,


   },
   props: {
	  paymentEdit: Object,
	  formRef: Object
   },
   mixins: [FormMixin, ToastMixin],
   data() {
	  return {
		 money: {
			decimal: ',',
			thousands: '.',
			prefix: '',
			suffix: '',
			precision: 2,
			masked: false
		 },
		 editorOption: {
			modules: {},
			placeholder: this.$i18n.t('generic.insert-text-here'),
		 },
		 pay: {
			reference: null,
			title: null,
			amount: false,
			datestamp: null,
			repeatable: false,
			interval: "week",
			repeatableUntil: null,
			categoryId: null,
			projectId: null,
			paymentMethod: null,
			notes: null,
			edit: false,
		 },
		 user: {
			name: null,
			surname: null,

		 },
		 contact: [],
		 guestsOptions: guestsOptions,
		 selectedPaymentMethod: ['Μετρητά'],
	  }
   },
   watch: {
	  paymentEdit() {
		 if (this.paymentEdit) {
			let clonePayEdit = _.cloneDeep(this.paymentEdit);
			this.pay = _.cloneDeep(clonePayEdit);
			this.convertCurrency(this.pay)
		 } else {
			this.pay = {
			   reference: null,
			   title: null,
			   amount: false,
			   datestamp: null,
			   repeatable: false,
			   interval: "week",
			   repeatableUntil: null,
			   categoryId: null,
			   projectId: null,
			   paymentMethod: null,
			   notes: null,
			   edit: false,
			}
		 }

	  },
   },

   methods: {

	  revertCurrency(index) {
		 this.pay.price = this.parseLocaleNumber(index.price, 'de');
	  },
	  convertCurrency(index) {
		 this.pay.price = new Intl.NumberFormat('de-DE', {
			style: 'currency',
			currency: 'EUR',
			currencyDisplay: "code"
		 }).format(index.price)
			 .replace("EUR", "")
			 .trim();

	  },
	  parseLocaleNumber(stringNumber, locale) {
		 var thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
		 var decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

		 return parseFloat(stringNumber
			 .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
			 .replace(new RegExp('\\' + decimalSeparator), '.')
		 );
	  },
	  getValidationState({dirty, validated, valid = null}) {
		 return validated ? valid : null;
	  },
	  getError(error) {
		 return `errors.${error
			 .toLowerCase()
			 .replaceAll(" ", "-")
			 .replace(".", "")}`;
	  },
	  removeNullProperties(obj) {
		 for (let key in obj) {
			if (obj[key] === null) {
			   delete obj[key];
			}
		 }
		 return obj
	  },
	  isValidISODate(date) {
		 let isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
		 return isoDateRegex.test(date);
	  },

	  onSubmitPayments() {


		 console.log('asd');




		 // this.pay.contactId = this.$store.getters.getProject.contactId
		 this.pay.projectId = this.$store.getters.getProject.id
		 if (this.pay.amount === undefined) {
			this.pay.amount = 0
		 }
		 if (!this.isValidISODate(this.pay.datestamp)) {
			this.pay.datestamp = this.pay.datestamp.toISOString().slice(0, 10);
		 }
		 if (!this.isValidISODate(this.pay.repeatableUntil)) {
			this.pay.repeatableUntil = null
		 }


		 if (this.paymentEdit === null || this.paymentEdit.edit === false) {
			this.pay.reference = moment().valueOf().toString().substring(5, 10);
			let clonePay = _.cloneDeep(this.pay);
			// clonePay.amount = this.parseLocaleNumber(clonePay.amount, 'de');


			this.$emit('getListOfPayment', this.removeNullProperties(clonePay));
			this.$emit('dirtyForm');
			this.resetField(this.pay);
		 } else {
			let clonePay = _.cloneDeep(this.pay);
			// clonePay.amount = this.parseLocaleNumber(clonePay.amount, 'de');
			this.pay.reference = this.paymentEdit?.reference

			this.$emit('getListOfPayment', clonePay);


			this.resetField(this.pay);
		 }

		 this.$emit('onSave');
		 this.paymentEdit.edit = false;

		 // this.$emit('getPay', this.pay);


	  },
   },


   computed: {
	  interval() {
		 return caldoSettings.intervalOptions;
	  },
	  getPayment() {
		 return [
			{text: 'Μετρητά', value: 'cash'},
			{text: 'Κατάθεση σε τράπεζα', value: 'Κατάθεση σε τράπεζα'},
			{text: 'Κάρτα', value: 'card'},
			{text: 'Paypal', value: 'paypal'},
		 ]
	  },
	  calendars() {
		 return this.$store.getters.getProject.title
	  },
	  showPaymentEdit() {
		 return this.paymentEdit?.reference
	  },

	  getName() {
		 return this.$store.getters.getProject.contact?.name + ' ' + this.$store.getters.getProject.contact?.surname
	  },


   },
   async created() {

	  await this.$store.dispatch("fetchProjectSetting");
	  this.$emit('getPay', this.pay);

	  // await this.$store.dispatch("fetchProject", this.$route.params.projectId)


	  // console.log(this.contactId)

	  if (this?.formRef?.contactId)
		 await this.$store.dispatch("fetchContact", this.formRef.contactId)


   }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>


