<template>
   <div>
	  <b-card-body class="invoice-padding">
		 <validation-observer ref="observer"
							  v-slot="{handleSubmit,  invalid}">
			<b-form
				ref="tasks-form"
				:style="{ height: trHeight }"
				class="repeater-form"
				@keyup="$emit('dirty')"
				@submit.prevent="repeateAgain"
			>
			   <!-- Row Loop -->
			   <div
				   v-for="(item, index) in form.tasks"
				   :id="item.id"
				   :key="item.id"
				   ref="row"
				   class="d-flex border rounded mb-1 bg-white shadow mx-2 repeater-row"
			   >
				  <b-row class="flex-grow-1 px-2 py-1 align-items-center">

					 <!-- Task -->
					 <b-col cols="3"
							md="3">
						<validation-provider v-slot="validationContext"
											 name="task"
											 rules="">
						   <b-form-group class="required"
										 label="Εργασία"
										 label-for="task-price">
							  <b-form-input
								  id="task-price"
								  v-model="item.title"
								  :state="getValidationState(validationContext)"
							  />
							  <b-form-invalid-feedback id="form-title-lice-feedback">
								 {{ validationContext.errors[0] }}
							  </b-form-invalid-feedback>
						   </b-form-group>
						</validation-provider>
					 </b-col>

					 <!--Start Date -->

					 <b-col cols="6"
							md="3">

						<b-form-group label="Ημ/νια έναρξης">
						   <date-picker
							   v-model="item.startDate"
							   :disabled="!item.title"
							   :show-time-header="true"
							   class="w-100"
							   format="DD-MM-YYYY HH:mm"
							   type="date"
							   valueType="YYYY-MM-DD HH:mm" />

						</b-form-group>

					 </b-col>

					 <!--End Date -->
					 <b-col cols="6"
							md="3">

						<b-form-group label="Ημ/νια λήξης">
						   <date-picker
							   v-model="item.endDate"
							   :disabled="!item.title || !item.startDate"
							   :show-time-header="true"
							   class="w-100"
							   format="DD-MM-YYYY HH:mm"
							   type="date"
							   valueType="YYYY-MM-DD HH:mm" />

						</b-form-group>
					 </b-col>


					 <!-- Status -->
					 <b-col cols="12"
							md="3">
						<b-form-group
							class="mb-1 mb-md-0 required"
							label="Κατάσταση"
							label-for="taskStatus"
						>
						   <v-select
							   v-model="item.status"
							   :clearable="false"
							   :disabled="!item.title"
							   :options="statuses"
							   :reduce="options=>options.label"
							   append-to-body
							   input-id="taskStatus"
							   label="title"
						   >
							  <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
							  <template #option="{ color, label }">
								 <div :class="`bg-${color}`"
									  class="rounded-circle d-inline-block mr-50"
									  style="height:10px;width:10px" />
								 <span> {{ label }}</span>
							  </template>

							  <template #selected-option="{ color, label }">
								 <div :class="`bg-${color}`"
									  class="rounded-circle d-inline-block mr-50"
									  style="height:10px;width:10px" />
								 <span> {{ label }}</span>
							  </template>
						   </v-select>
						</b-form-group>
					 </b-col>

				  </b-row>

				  <div
					  class="d-flex flex-column justify-content-between border-left py-50 px-25"
				  >
					 <feather-icon
						 class="cursor-pointer"
						 icon="XIcon"
						 size="16"
						 @click="removeItem(index)"
					 />
					 <feather-icon
						 :id="`form-item-task-icon-${index}`"
						 class="cursor-pointer"
						 icon="SettingsIcon"
						 size="16"
					 />

					 <!-- Setting Item Form -->
					 <b-popover
						 :ref="`form-item-task-popover-${index}`"
						 :target="`form-item-task-icon-${index}`"
						 placement="lefttop"
						 triggers="click"
					 >

						<b-row>
						   <b-col class="mb-1"
								  cols="12"
								  md="12">
							  <b-form-group class="mb-1 mb-md-0"
											label="Συνεργάτες"
											label-for="demoServices">
								 <v-select
									 v-model="item.partners"
									 :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									 :disabled="!item.title"
									 :options="allPartners"
									 item-value="id"
									 label="name"
									 multiple
									 placehoder="Επιλέξτε..."
								 >
									<template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
								 </v-select>
							  </b-form-group>
						   </b-col>

						   <b-col cols="12">
							  <b-form-group label="Σημειώσεις"
											label-for="task-notes">
								 <b-form-textarea
									 id="task-notes"
									 v-model="item.notes"
									 :disabled="!item.title"
									 class="mb-2 mb-lg-0"
								 />
							  </b-form-group>
						   </b-col>
						   <b-col cols="12">
							  <b-form-group label="Διάρκεια"
											label-for="task-notes">

								 {{ getDiffHours(item.startDate, item.endDate) }}
							  </b-form-group>
						   </b-col>


						   <b-col class="d-flex justify-content-between mt-1"
								  cols="12">
							  <b-button
								  class="d-none"
								  size="sm"
								  variant="outline-primary"
								  @click="
                        () => {
                          $refs[`form-item-task-popover-${index}`][0].$emit(
                            'close'
                          );
                        }
                      "
							  >
								 Αποθήκευση
							  </b-button>
							  <b-button
								  size="sm"
								  variant="outline-secondary"
								  @click="
                        () => {
                          $refs[`form-item-task-popover-${index}`][0].$emit(
                            'close'
                          );
                        }
                      "
							  >
								 Κλείσιμο
							  </b-button>
						   </b-col>
						</b-row>

					 </b-popover>
				  </div>
			   </div>
			</b-form>
		 </validation-observer>
		 <b-button
			 v-ripple.400="'rgba(255, 255, 255, 0.15)'"
			 class="mt-2 ml-2"
			 size="sm"
			 variant="outline-success"
			 @click="repeateAgain"
		 >
			<feather-icon class="mr-25"
						  icon="PlusIcon" />
			<span>{{ $t("generic.add") }}</span>
		 </b-button>
	  </b-card-body>
   </div>
</template>

<script>
import {
   BButton,
   BCard,
   BCardBody,
   BCol,
   BDropdown,
   BDropdownItem,
   BForm,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BFormTextarea,
   BPagination,
   BPopover,
   BRow,
   BTable,
   VBToggle,
} from "bootstrap-vue";
import {heightTransition} from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import AddService from "@/views/caldo-global/AddService";
import {required_if} from "vee-validate/dist/rules";
import {Greek} from "flatpickr/dist/l10n/gr.js"
import moment from "moment";

extend("required_if", required_if)
export default {
   components: {
	  BButton,
	  BForm,
	  BFormGroup,
	  BFormInput,
	  BRow,
	  BCol,
	  BCard,
	  BCardBody,
	  BPopover,
	  vSelect,
	  VBToggle,
	  ValidationObserver, ValidationProvider,
	  AddService,
	  BTable,
	  BDropdown,
	  BDropdownItem,
	  BFormInvalidFeedback,
	  BPagination,
	  BFormTextarea,
   },
   directives: {
	  Ripple,
   },
   props: {
	  form: Object
   },
   mixins: [heightTransition],
   watch: {
	  'form.tasks': {
		 handler: async function (val, oldVal) {
			// console.log(this.form.tasks.hasOwnProperty('');
			// const isValid = await this.$refs.observer.validate();
			// this.$emit('handleForm', isValid)
			// await this.$refs.observer.validate();
		 },
		 deep: true
	  }
   },
   data() {
	  return {
		 config: {
			locale: Greek,
			altInput: true,
			altFormat: 'd-m-Y H:i',
			dateFormat: 'Y-m-d H:i',
			enableTime: true,
			time_24hr: true,
		 },
		 allPartners: []
	  };
   },
   //ΕΙΝΑΙ ΟΚ, το έχω βάλει στο store > general
   computed: {
	  statuses() {
		 return this.$store.getters.getCalendars
	  }
   },

   async created() {
	  const {data} = await axiosIns.get(`/partner`, {
		 params: {
			"rpp": 100,
			"orderBy": ["surname", "name", "email"],
			"sortBy": "asc"
		 }
	  })
	  this.allPartners = data.data


	  window.addEventListener("resize", this.initTrHeight);
   },
   destroyed() {
	  window.removeEventListener("resize", this.initTrHeight);
   },
   methods: {

	  getDiffHours(start, end) {

		 var a = moment(start);//now
		 var b = moment(end);
		 return b.diff(a, 'hours') + ' ώρες';

	  },
	  test(val) {


	  },
	  getValidationState({dirty, validated, valid = null}) {

		 return validated ? valid : null;
	  },

	  repeateAgain() {
		 this.form.tasks.push({partners: [], status: 'Σε εξέλιξη'});


	  },

	  removeItem(index) {
		 this.form.tasks.splice(index, 1);
		 this.trTrimHeight(this.$refs.row[0].offsetHeight);
	  },
	  initTrHeight() {
		 this.trSetHeight(null);
		 this.$nextTick(() => {


		 });
	  },
   },
};
</script>

<style lang="scss"
	   scoped>
.repeater-form {
   overflow: hidden;
   transition: 0.35s height;
}
</style>

<style lang="scss"
	   scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/libs/vue-flatpicker.scss";


.add-new-client-header {
   padding: 0.5rem 1rem;
}


.add-new-client-header:hover {
   background-color: rgba(40, 199, 111, 0.12);
}


.invoice-padding {
   padding: 0 0 1.5rem 0;
}


.repeater-row .form-group {
   margin-bottom: 0;
}


.form-item-section {
   background-color: $product-details-bg;
}


.form-item-action-col {
   width: 27px;
}


.repeater-form {
   // overflow: hidden;
   transition: 0.35s height;
}


.repeater-row {
   .dark-layout & {
	  background: $theme-dark-body-bg !important;
   }
}


.v-select {
   &.item-selector-title,
   &.payment-selector {
	  background-color: #fff;

	  .dark-layout & {
		 background-color: unset;
	  }
   }
}


.dark-layout {
   .form-item-section {
	  background-color: $theme-dark-body-bg !important;

	  .row .border {
		 background-color: $theme-dark-card-bg;
	  }
   }
}
</style>
