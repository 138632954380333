import { render, staticRenderFns } from "./ProjectsTabExpences.vue?vue&type=template&id=319c7a43&scoped=true&"
import script from "./ProjectsTabExpences.vue?vue&type=script&lang=js&"
export * from "./ProjectsTabExpences.vue?vue&type=script&lang=js&"
import style0 from "./ProjectsTabExpences.vue?vue&type=style&index=0&id=319c7a43&lang=scss&scoped=true&"
import style1 from "./ProjectsTabExpences.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "319c7a43",
  null
  
)

export default component.exports