<template>
   <div ref="projectBody">
	  <b-alert variant="danger">
		 <h4 class="alert-heading">{{ $t('contacts.error') }}</h4>
		 <div class="alert-body">
			{{ $t('contacts.error-msg') }}
			<b-link class="alert-link"> {{ $t('contacts.contacts-list') }}</b-link>
			{{ $t('contacts.contacts-more') }}
		 </div>
	  </b-alert>

	  <b-row class="match-height">
		 <b-col cols="12"
				md="9">
			<projects-card-info :all-projects="allProject"
								:form="form"></projects-card-info>
		 </b-col>
		 <b-col cols="12"
				md="3">
			<projects-card-edit
				:form="form"
				@submit="onSubmit"></projects-card-edit>
		 </b-col>
	  </b-row>

	  <b-card no-body>
		 <b-tabs card
				 pills>
			<b-tab active
				   no-body>
			   <template #title>
				  <feather-icon class="mr-0 mr-sm-50"
								icon="ShoppingCartIcon"
								size="16" />
				  <span class="d-none d-sm-inline">Υπηρεσίες</span>
			   </template>
			   <projects-tab-services :form="form"
									  @dirty="isDirty" />
			</b-tab>
			<b-tab no-body>
			   <template #title>
				  <feather-icon class="mr-0 mr-sm-50"
								icon="LayersIcon"
								size="16" />
				  <span class="d-none d-sm-inline">Tasks</span>
			   </template>
			   <projects-tab-tasks :form="form"
								   @dirty="isDirty"
								   @handleForm="isValidForm($event,'isDisabledTasks')" />
			</b-tab>
			<b-tab no-body>
			   <template #title>
				  <feather-icon class="mr-0 mr-sm-50"
								icon="DollarSignIcon"
								size="16" />
				  <span class="d-none d-sm-inline">Πληρωμές</span>
			   </template>
			   <projects-tab-payments :form="formTemp"
									  @submit="onSubmit"
									  @dirty="isDirty" />


			</b-tab>
			<b-tab no-body>
			   <template #title>
				  <feather-icon class="mr-0 mr-sm-50"
								icon="DollarSignIcon"
								size="16" />
				  <span class="d-none d-sm-inline">Εξοδα</span>
			   </template>
			   <projects-tab-expences :form="formTemp"
									  @submit="onSubmit"
									  @dirty="isDirty" />


			</b-tab>
			<b-tab no-body>
			   <template #title>
				  <feather-icon class="mr-0 mr-sm-50"
								icon="PenToolIcon"
								size="16" />
				  <span class="d-none d-sm-inline">Σημειώσεις</span>
			   </template>
			   <div class="mx-2 mb-2">
				  <b-row>
					 <b-col cols="12">
						<quillEditor v-model="form.notes"
									 :options="editorOptions"
									 @keyup.native="isDirty" />
					 </b-col>
				  </b-row>
			   </div>

			</b-tab>
			<b-tab no-body>
			   <template #title>
				  <feather-icon class="mr-0 mr-sm-50"
								icon="FileIcon"
								size="16" />
				  <span class="d-none d-sm-inline">Αρχεία</span>
			   </template>
			   <projects-tab-files @dirty="isDirty" />
			</b-tab>
		 </b-tabs>

	  </b-card>

	  <sidebar-project :all-categories="allCategories"
					   :all-projects="allProject"
					   :form="form"
					   :isDisabled="isDisabled"
					   title="Επεξεργασία"
					   @dirty="isDirty"
					   @submit="onSubmit" />

   </div>
</template>


<script>
import {BAlert, BCard, BCardBody, BCol, BLink, BRow, BTab, BTabs} from "bootstrap-vue"
import ProjectsCardEdit from './ProjectsCardEdit'
import ProjectsCardInfo from './ProjectsCardInfo'
import ProjectsTabServices from './ProjectsTabServices'
import ProjectsTabTasks from './ProjectsTabTasks'
import ProjectsTabNotes from './ProjectsTabNotes'
import ProjectsTabFiles from './ProjectsTabFiles'
import ProjectsTabPayments from './ProjectsTabPayments'
import SidebarProject from '@/views/caldo-global/SidebarProject'
import {quillEditor} from "vue-quill-editor";
import _ from "lodash";
import FormMixin from "@/Mixins/FormMixin";
import axiosIns from "@/libs/axios";
import ToastMixin from "@/Mixins/ToastMixin";
import ProjectsTabExpences from "@/views/projects/projects-view/ProjectsTabExpences";


export default {
   components: {
	  ProjectsTabExpences,
	  BCard,
	  BCardBody,
	  BAlert,
	  BLink,
	  BRow,
	  BCol,
	  BTabs,
	  BTab,
	  ProjectsCardEdit,
	  ProjectsCardInfo,
	  SidebarProject,
	  ProjectsTabServices,
	  ProjectsTabTasks,
	  ProjectsTabPayments,
	  quillEditor,
	  ProjectsTabNotes,
	  ProjectsTabFiles,
   },
   mixins: [FormMixin, ToastMixin],
   data() {
	  return {
		 editorOptions: {
			placeholder: this.$i18n.t('generic.insert-text-here'),
			theme: 'snow'
		 },
		 changed: false,
		 valid: {
			isDisabledService: true,
			isDisabledTasks: true,
		 },
		 allProject: [],
		 allCategories: [],
		 isDisabled: true,
		 formTemp: {},
		 touched: false,
		 form: {
			"title": null,
			"contactId": null,
			"categoryId": null,
			"status": null,
			"startDate": null,
			"deadline": null,
			"notes": null,
			"services": [{}],
			"tasks": [{}],
			"payments": [{}]
		 }
	  }
   },

   watch: {
	  form: {
		 handler(val, oldVal) {


			let sum = this.form.services.reduce(function (sum, value) {

			   let price = value.price
			   if (value?.discount) {
				  price = price - (price * value.discount / 100)
			   }
			   if (value?.vat) {
				  price = price * 1.24
			   }
			   return sum + price
			}, 0);
			let paid = this.form.payments.reduce(function (sum, value) {
			   return sum + value.price
			}, 0);
			this.$store.dispatch('updateSum', sum);
			this.$store.dispatch('updatePaid', paid);

			this.formTemp = this.form
		 },
		 deep: true
	  },
   },
   async beforeRouteLeave(to, from, next) {
	  if (this.touched) {
		 const {isConfirmed} = await window.swall({
			title: 'Είστε σίγουρος?',
			text: "Θέλετε πραγματικά να φύγετε; Έχετε μη αποθηκευμένες αλλαγές!",
			type: 'warning',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#7367f0',
			cancelButtonColor: '#82868b',
			reverseButtons: true,
			confirmButtonText: 'Ναι!',
			cancelButtonText: 'Όχι!',
			buttonsStyling: true,
			animation: true
		 });
		 if (isConfirmed) {
			return next()
		 } else {
			return next(false)
		 }
	  }
	  return next()
   },
   methods: {
	  isDirty() {
		 this.touched = true;
	  },
	  removeNullProperties(arr) {
		 arr.forEach(obj => {
			for (let key in obj) {
			   if (obj[key] === null) {
				  delete obj[key];
			   }
			}
		 });
	  },
	  async onSubmit(e) {
		 console.log('test');
		 const cloneForm = _.cloneDeep(this.form)
		 this.form.tasks.forEach((el, idx) => {
			if (el.hasOwnProperty('partners')) {
			   el.partners.forEach((part, index) => {
				  if (typeof part === "object")
					 this.form.tasks[idx].partners[index] = part.id
			   })
			}
		 })


		 this.form.tasks = this.form.tasks.map((el, index) => {

			if (!el.hasOwnProperty('title')) {
			   return {}
			}
			return this.removeEmpty(el);
		 })


		 this.clearEmptiesObject(this.form.tasks);


		 this.form.payments = this.form.payments.map(el => this.removeEmpty(el));

		 const form = _.cloneDeep(this.form)
		 form.tasks = form.tasks.filter(
			 obj => !(obj && Object.keys(obj).length === 0)
		 );
		 form.services.map(el => {
			if (el.discount) {
			   let sum = el.price = el.price - (el.price * el.discount / 100)
			   el.price = sum;
			}
			if (el?.vat) {
			   el.price = el.price * 1.24
			}

			return this.removeEmptySecond(el);
		 })



		 for (var i = 0; i < form.expenses.length; i++) {
			if (typeof form.expenses[i].title === 'number') {
			   form.expenses[i].title = form.expenses[i].title.toString();
			}
			if (typeof form.expenses[i].notes === 'number') {
			   form.expenses[i].notes = form.expenses[i].notes.toString();
			}
		 }

		this.removeNullProperties(form.expenses)

		 await this.$store.dispatch("updateProject", this.removeEmpty(form))
		 this.form = cloneForm;
		 this.touched = false;
		 this.notify(`Αποθηκεύτηκε`)
	  },
	  isValidForm(isValid, value) {
		 this.valid[value] = isValid

		 this.isDisabled = !_.every(this.valid)


	  },
	  addFieldTo(...fields) {
		 fields.forEach(field => {
			console.log(field)
			let defaultValue = field === 'tasks' ? {status: 'Σε εξέλιξη'} : {};
			if (!this.form[field].length) {
			   this.form[field].push(defaultValue)
			}
		 })
	  }
   },
   computed: {
	  project() {
		 return this.$store.getters.getProject
	  },
   },
   mounted() {
	  this.$refs.projectBody.style.opacity = 0
	  setTimeout(() => {
		 this.$refs.projectBody.style.opacity = 1
	  }, 1360)
   },


   async created() {

	  await this.$store.dispatch('fetchProject', this.$route.params.projectId)
	  this.form = await this.$store.getters.getProject

	  const responseProject = await axiosIns.get(`/contact`, {
		 params: {
			rpp: 100,
			orderBy: ["surname"],
		 }
	  })
	  this.allCategories = responseProject.data.data

	  const responseProjects = await axiosIns.get(`/project-category`, {
		 params: {
			rpp: 100
		 }
	  })
	  this.allProject = responseProjects.data


	  await this.$store.dispatch('fetchContact', this.form.contactId)

	  this.form.services.map(el => {
		 if (el.discount) {
			el.price = el.price / (1 - el.discount / 100)
		 }
		 if (el?.vat) {
			el.price = el.price / 1.24
		 }
	  })


	  if (!this.form.tasks.length) {
		 this.form.tasks.push()
	  }
	  this.addFieldTo("services", "tasks")
   }
};
</script>

<style lang="css">
/*@import '@core/scss/vue/libs/quill.scss';*/

.ql-editor {
   min-height: 220px;
}

.card-header-pills {
   margin-right: 0 !important;
   margin-left: 0 !important;
}

.nav-pills {
   margin-bottom: 0 !important;
}
</style>