<template>
   <div class="mx-2 mb-2">
	  <b-row>
		 <b-col cols="12">
			<quillEditor :options="editorOptions" />
		 </b-col>
	  </b-row>
   </div>

</template>

<script>
import {BCardText, BCol, BRow} from 'bootstrap-vue'
import {quillEditor} from 'vue-quill-editor'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

export default {
   components: {
	  BRow,
	  BCol,
	  BCardCode,
	  BCardText,
	  quillEditor,
   },
   data() {
	  return {
		 editorOptions: {
			placeholder: this.$i18n.t('generic.insert-text-here'),
			theme: 'snow'
		 }
	  }
   }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';


.ql-editor {
   min-height: 220px;
}
</style>
