<template>
   <b-card
	   class="border-primary"
	   no-body
   >
	  <b-card-header class="d-flex justify-content-between align-items-center pb-25">
		 <h5 class="mb-0">
			{{ form.status }}
		 </h5>
		 <b-dropdown
			 class="project-dropdown"
			 no-caret
			 right
			 toggle-class="p-0"
			 variant="link"
		 >
			<template #button-content>
			   <feather-icon
				   class="text-body cursor-pointer"
				   icon="MoreVerticalIcon"
				   size="18"
			   />
			</template>
			<b-dropdown-item v-b-toggle.sidebar-project>
			   <feather-icon icon="EditIcon" />
			   <span class="align-middle ml-50">{{ $t("generic.edit") }}</span>
			</b-dropdown-item>
			<b-dropdown-divider></b-dropdown-divider>
			<b-dropdown-item @click="$emit('submit')">
			   <feather-icon class="text-danger"
							 icon="TrashIcon" />
			   <span class="align-middle text-danger ml-50">{{ $t("generic.delete") }}</span>
			</b-dropdown-item>

		 </b-dropdown>
		 <small class="text-muted w-100">{{ now }}</small>
	  </b-card-header>

	  <b-card-body class="d-flex flex-column justify-content-between">
		 <ul class="list-unstyled mt-1 ">
			<li class="price-detail">
			   <div class="">
				  {{ $t("projects.total") }}
			   </div>
			   <div class="detail-amt">
				  €{{ convertFormatPrice($store.getters.getLivePaymentSum) }}
			   </div>
			</li>
			<li class="price-detail">
			   <div class="">
				  {{ $t("generic.payments") }}
			   </div>
			   <div class="detail-amt discount-amt">
				  €{{ convertFormatPrice($store.getters.getLivePaymentPaid) }}

			   </div>
			</li>
			<li class="price-detail">
			   <div class="">
				  {{ text }}
			   </div>
			   <div ref="livePayment"
					:class="color"
					class="detail-amt">
				  €{{ getSum($store.getters.getLivePaymentSum, $store.getters.getLivePaymentPaid) }}
			   </div>
			</li>
		 </ul>

		 <b-button
			 v-ripple.400="'rgba(255, 255, 255, 0.15)'"
			 block
			 class="mt-auto"
			 variant="primary"
			 @click="$emit('submit')"
		 >
			<feather-icon
				class="mr-50"
				icon="SaveIcon"
			/>
			<span class="align-middle">{{ $t("generic.save") }}</span>
		 </b-button>

	  </b-card-body>
   </b-card>
</template>

<script>
import {
   BBadge,
   BButton,
   BCard,
   BCardBody,
   BCardHeader,
   BDropdown,
   BDropdownDivider,
   BDropdownItem,
   VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import CurrencyMixin from "@/Mixins/CurrencyMixin";
import caldoSettings from "@/caldoSettings";

export default {
   directives: {
	  Ripple,
	  "b-toggle": VBToggle,
   },
   props: {
	  form: Object,
   },
   mixins: [CurrencyMixin],
   computed: {
	  now() {
		 return moment(this.form.startDate).lang('el').format('DD MMM YYYY')
	  }
   },
   data() {
	  return {
		 sum: 0,
		 text: 0,
		 color: 'text-danger'
	  };
   },
   watch: {
	  'sum': {
		 deep: true,
		 handler(newVal) {
			if (newVal < 0) {
			   this.text = 'Πλεόνασμα'
			   this.color = 'text-success'
			} else {

			   this.text = 'Ωφειλή'
			   this.color = 'text-danger'
			}
		 }
	  }
   },
   methods: {
	  getSum(price1, price2) {
		 this.sum = price1 - price2
		 return this.convertFormatPrice(this.abs(this.sum * -1));
	  },
	  abs(value) {

		 return caldoSettings.abs(value)
	  }
   },


   components: {
	  BCard,
	  BCardHeader,
	  BCardBody,
	  BBadge,
	  BButton,
	  BDropdown,
	  BDropdownItem,
	  BDropdownDivider,
   },
}
</script>

<style scoped>
.price-detail {
   display: flex;
   -webkit-box-pack: justify;
   -ms-flex-pack: justify;
   justify-content: space-between;
   margin-bottom: .45rem;
}

.text-success {
   color: green;
}

.detail-amt {
   font-weight: 500;
}
</style>
