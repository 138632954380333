<template>

   <!-- Table Container Card -->
   <div>

	  <div class="m-2">

		 <!-- Table Top -->
		 <b-row>

			<!-- Per Page -->
			<b-col
				class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
				cols="12"
				md="6"
			>
			   <label>{{ $t('tables.entries1') }}</label>
			   <v-select
				   v-model="perPage"
				   :clearable="false"
				   :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
				   :options="perPageOptions"
				   class="per-page-selector d-inline-block ml-50 mr-1"
			   >
				  <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
			   </v-select>
			   <b-button
				   variant="primary"
				   @click="onSidebar()"
			   >
				  Προσθήκη εγγραφής
			   </b-button>
			</b-col>

			<!-- Search -->
			<!--			<b-col-->
			<!--				cols="12"-->
			<!--				md="6"-->
			<!--			>-->
			<!--			   <div class="d-flex align-items-center justify-content-end">-->
			<!--				  <b-form-input-->
			<!--					  v-model="searchQuery"-->
			<!--					  class="d-inline-block mr-1"-->
			<!--					  placeholder="Search..."-->
			<!--				  />-->
			<!--				  &lt;!&ndash;				  <v-select&ndash;&gt;-->
			<!--				  &lt;!&ndash;					  v-model="statusFilter"&ndash;&gt;-->
			<!--				  &lt;!&ndash;					  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"&ndash;&gt;-->
			<!--				  &lt;!&ndash;					  :options="statusOptions"&ndash;&gt;-->
			<!--				  &lt;!&ndash;					  class="invoice-filter-select"&ndash;&gt;-->
			<!--				  &lt;!&ndash;					  placeholder="Select Status"&ndash;&gt;-->
			<!--				  &lt;!&ndash;				  >&ndash;&gt;-->
			<!--				  &lt;!&ndash;					 <template #selected-option="{ label }">&ndash;&gt;-->
			<!--				  &lt;!&ndash;                <span class="text-truncate overflow-hidden">&ndash;&gt;-->
			<!--				  &lt;!&ndash;                  {{ label }}&ndash;&gt;-->
			<!--				  &lt;!&ndash;                </span>&ndash;&gt;-->
			<!--				  &lt;!&ndash;					 </template>&ndash;&gt;-->
			<!--				  &lt;!&ndash;				  </v-select>&ndash;&gt;-->
			<!--			   </div>-->
			<!--			</b-col>-->
		 </b-row>

	  </div>

	  <b-table
		  ref="refInvoiceListTable"
		  :empty-text="$t('errors.no-entries-found')"
		  :fields="tableColumns"
		  :items="resultPaginated"
		  :sort-by.sync="sortBy"
		  :sort-desc.sync="isSortDirDesc"
		  class="position-relative"
		  primary-key="id"
		  responsive
		  show-empty
	  >

		 <template #head(invoiceStatus)>
			<feather-icon
				class="mx-auto"
				icon="TrendingUpIcon"
			/>
		 </template>

		 <!-- Column: Id -->
		 <template #cell(id)="data">
			<b-link
				:to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
				class="font-weight-bold"
			>
			   #{{ data.value }}
			</b-link>
		 </template>

		 <!-- Column: Id -->
		 <template #cell(id)="data">
			<b-link
				:to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
				class="font-weight-bold"
			>
			   #{{ data.value }}
			</b-link>
		 </template>

		 <template #cell(reference)="data">
			<b-link
				class="font-weight-bold"
				@click="onEditPay(data)"
			>
			   #{{ data.value }}
			</b-link>
		 </template>

		 <template #cell(date)="data">
			{{ getDate(data.item) }}
		 </template>


		 <!-- Column: Client -->
		 <template #cell(client)="data">
			<b-media vertical-align="center">
			   <template #aside>
				  <b-avatar
					  :src="data.item.avatar"
					  :text="avatarText(data.item.client.name)"
					  :variant="`light-${resolveClientAvatarVariant(data.item.invoiceStatus)}`"
					  size="32"
				  />
			   </template>
			   <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.client.name }}
          </span>
			   <small class="text-muted">{{ data.item.client.companyEmail }}</small>
			</b-media>
		 </template>

		 <!-- Column: Issued Date -->
		 <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
		 </template>
		 <!-- Column: price Date -->
		 <template #cell(price)="data">
        <span class="text-nowrap">
          {{ convertCurrency(data.item.price) }}
        </span>
		 </template>

		 <!-- Column: Balance -->
		 <template #cell(balance)="data">
			<template v-if="data.value === 0">
			   <b-badge
				   pill
				   variant="light-success"
			   >
				  Paid
			   </b-badge>
			</template>
			<template v-else>
			   {{ data.value }}
			</template>
		 </template>

		 <!-- Column: Actions -->
		 <template #cell(actions)="data">

			<div class="text-nowrap d-flex">


			   <div
				   id="my-sidebar"
				   ref="mySidebar"
				   class="mr-2"
				   style="cursor: pointer"
				   @click="onEditPay(data)">
				  <feather-icon icon="EditIcon" />


			   </div>
			   <div
				   style="cursor: pointer"
				   @click="removeItem(data.index)">
				  <feather-icon icon="TrashIcon" />

			   </div>
			</div>
		 </template>

	  </b-table>


	  <div class="mx-2 mb-2">
		 <b-row>

			<b-col
				class="d-flex align-items-center justify-content-center justify-content-sm-start"
				cols="12"
				sm="6"
			>
			   <span class="text-muted">
				  {{ $t('tables.view-page') }}
				  {{ dataMeta.from }}
				  {{ $t('tables.from') }}
				  {{ currentPage }}
				  {{ $t('tables.in-total') }}
				  {{ resultPaginated.length }}
				  {{ $t('tables.entries1') }}</span>
			</b-col>
			<!-- Pagination -->
			<b-col
				class="d-flex align-items-center justify-content-center justify-content-sm-end"
				cols="12"
				sm="6"
			>

			   <b-pagination
				   v-model="currentPage"
				   :per-page="perPage"
				   :total-rows="totalInvoices"
				   class="mb-0 mt-1 mt-sm-0"
				   first-number
				   last-number
				   next-class="next-item"
				   prev-class="prev-item"
			   >
				  <template #prev-text>
					 <feather-icon
						 icon="ChevronLeftIcon"
						 size="18"
					 />
				  </template>
				  <template #next-text>
					 <feather-icon
						 icon="ChevronRightIcon"
						 size="18"
					 />
				  </template>
			   </b-pagination>

			</b-col>

		 </b-row>
	  </div>
	  <sidebar-payment :formRef="formRef"
					   :paymentEdit="pay"
					   @dirtyForm="makeDirtyForm"
					   @getListOfPayment="onPayment"
					   @onSave="onSave"
					   @getPay="getPay" />
   </div>
</template>

<script>
import {
   BAvatar,
   BBadge,
   BButton,
   BCard,
   BCol,
   BDropdown,
   BDropdownItem,
   BFormInput,
   BLink,
   BMedia,
   BPagination,
   BRow,
   BTable,
   BTooltip,
} from 'bootstrap-vue'
import {avatarText} from '@core/utils/filter'
import vSelect from 'vue-select'
import {computed, onUnmounted, ref, watch} from '@vue/composition-api'
import store from '@/store'
import invoiceStoreModule from './invoiceStoreModule'
import SidebarPayment from "@/views/caldo-global/SidebarPayment";
import {paginateArray, sortCompare} from "@/@fake-db/utils";
import {useToast} from "vue-toastification/composition";
import moment from "moment";

import axiosIns from "@/libs/axios";
import FormMixin from "@/Mixins/FormMixin";

export default {
   components: {
	  BCard,
	  BRow,
	  BCol,
	  BFormInput,
	  BButton,
	  BTable,
	  BMedia,
	  BAvatar,
	  BLink,
	  BBadge,
	  BDropdown,
	  BDropdownItem,
	  BPagination,
	  BTooltip,
	  SidebarPayment,
	  vSelect,
   },
   mixins: [FormMixin],
   props: {
	  form: Object
   },
   methods: {
	  makeDirtyForm() {
		 this.$emit('dirty')
	  },onSave(){
		 this.$emit('submit')
	  }
   },
   async created() {

	  window.swall = this.$swal;
	  window.toast = this.$toast;
   },
   setup(props, context) {

	  const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'


	  // Register module
	  if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

	  // UnRegister on leave
	  onUnmounted(() => {
		 if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
	  })


	  const allPayments = ref([])
	  const totalInvoices = ref(allPayments.value.length)
	  const refInvoiceListTable = ref(null)
	  const perPage = ref(10)
	  const currentPage = ref(1)
	  const resultPaginated = ref([])
	  const perPageOptions = [10, 25, 50, 100]
	  const searchQuery = ref('')
	  const sortBy = ref('id')
	  const isSortDirDesc = ref(true)
	  const statusFilter = ref(null)
	  const pay = ref(null)
	  const formRef = ref()

	  const router = context.root.$route;


	  setTimeout(() => {
		 formRef.value = props.form
		 allPayments.value = props.form.payments
		 resultPaginated.value = allPayments.value
	  }, 800)


	  const getPay = e => {
		 // pay.value = e
	  }
	  const removeItem = async (index) => {
		 // allPayments.value.splice(index, 1)
		 const {isConfirmed} = await swall({
			title: 'Είστε σίγουρος?',
			text: "Δεν θα μπορείτε να το επαναφέρετε!",
			type: 'warning',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#7367f0',
			cancelButtonColor: '#82868b',
			reverseButtons: true,
			confirmButtonText: 'Ναι, να διαγραφεί\n!',
			cancelButtonText: 'Όχι, ακύρωση!',
			buttonsStyling: true,
			animation: true
		 });
		 if (isConfirmed) {
			props.form.payments.splice(index, 1)
			allPayments.value = props.form.payments
			resultPaginated.value = allPayments.value
		 }
	  }
	  const onSidebar = () => {

		 pay.value = {
			"reference": null,
			"contactId": null,
			"projectId": null,
			"status": null,
			"price": 0,
			"date": moment().format('YYYY-MM-DD'),
			"notes": null,
			"discount": null,
			edit: false,
		 };
		 context.root.$emit('bv::toggle::collapse', 'sidebar-payment')
	  }

	  const onEditPay = data => {

		 pay.value = data.item;
		 pay.value.edit = true;
		 context.root.$emit('bv::toggle::collapse', 'sidebar-payment')


	  }
	  const getDate = (item) => {
		 if (item.date)
			return moment(item.date).format("DD-MM-YYYY")
		 return '-'
	  }

	  const convertCurrency = (index) => {
		 return new Intl.NumberFormat('de-DE', {
			style: 'currency',
			currency: 'EUR',
			currencyDisplay: "code"
		 }).format(index)
			 .replace("EUR", "")
			 .trim();

	  }
	  const onPayment = async (e) => {
		 let match = _.find(allPayments.value, {reference: e.reference});
		 if (match) {
			let index = _.indexOf(allPayments.value, _.find(allPayments.value, {reference: e.reference}));
			allPayments.value.splice(index, 1, e);
		 } else {
			allPayments.value.push(e)
		 }


		 console.log('asdsad')
		 context.root.$emit('dirty')
		 // context.root.$emit('submit')
		 props.form.payments = allPayments.value
		 resultPaginated.value = paginateArray(allPayments.value, perPage.value, currentPage.value)
		 totalInvoices.value = allPayments.value.length
		 context.root.$emit('bv::toggle::collapse', 'sidebar-payment')
	  }


	  const statusOptions = [
		 'Downloaded',
		 'Draft',
		 'Paid',
		 'Partial Payment',
		 'Past Due',
	  ]

	  const toast = useToast()


	  // Table Handlers
	  const tableColumns = [
		 {key: 'reference', label: '#', sortable: true},
		 {key: 'date', label: 'Ημερομηνία', sortable: true},
		 {key: 'status', label: 'Πληρωμή', sortable: true},
		 {key: 'price', label: 'Tιμή', sortable: true, formatter: val => `${val}`},
		 // { key: 'balance', sortable: true },
		 {key: 'actions', label: 'Ενέργειες'},
	  ]


	  const dataMeta = computed(() => {
		 const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
		 return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalInvoices.value,
		 }
	  })

	  const refetchData = () => {
		 refInvoiceListTable.value.refresh()
	  }

	  watch([currentPage, perPage, searchQuery, statusFilter, sortBy], () => {
		 // refetchData()
		 allPayments.value = allPayments.value.sort(sortCompare(sortBy.value))
		 if (isSortDirDesc.value) allPayments.value.reverse()

		 resultPaginated.value = paginateArray(allPayments.value, perPage.value, currentPage.value)
	  })

	  // const fetchInvoices = (ctx, callback) => {
	  //  store
	  // 	 .dispatch('app-invoice/fetchInvoices', {
	  // 		q: searchQuery.value,
	  // 		perPage: perPage.value,
	  // 		page: currentPage.value,
	  // 		sortBy: sortBy.value,
	  // 		sortDesc: isSortDirDesc.value,
	  // 		status: statusFilter.value,
	  // 	 })
	  // 	 .then(response => {
	  // 		const {invoices, total} = response.data
	  //
	  //
	  // 		callback(invoices)
	  // 		totalInvoices.value = total
	  // 	 })
	  // 	 .catch(() => {
	  // 		toast({
	  // 		   component: ToastificationContent,
	  // 		   props: {
	  // 			  title: "Error fetching invoices' list",
	  // 			  icon: 'AlertTriangleIcon',
	  // 			  variant: 'danger',
	  // 		   },
	  // 		})
	  // 	 })
	  // }

	  // *===============================================---*
	  // *--------- UI ---------------------------------------*
	  // *===============================================---*

	  const resolveInvoiceStatusVariantAndIcon = status => {
		 if (status === 'Partial Payment') return {variant: 'warning', icon: 'PieChartIcon'}
		 if (status === 'Paid') return {variant: 'success', icon: 'CheckCircleIcon'}
		 if (status === 'Downloaded') return {variant: 'info', icon: 'ArrowDownCircleIcon'}
		 if (status === 'Draft') return {variant: 'primary', icon: 'SaveIcon'}
		 if (status === 'Sent') return {variant: 'secondary', icon: 'SendIcon'}
		 if (status === 'Past Due') return {variant: 'danger', icon: 'InfoIcon'}
		 return {variant: 'secondary', icon: 'XIcon'}
	  }

	  const resolveClientAvatarVariant = status => {
		 if (status === 'Partial Payment') return 'primary'
		 if (status === 'Paid') return 'danger'
		 if (status === 'Downloaded') return 'secondary'
		 if (status === 'Draft') return 'warning'
		 if (status === 'Sent') return 'info'
		 if (status === 'Past Due') return 'success'
		 return 'primary'
	  }


	  return {
		 tableColumns,
		 perPage,
		 removeItem,
		 currentPage,
		 totalInvoices,
		 dataMeta,
		 perPageOptions,
		 searchQuery,
		 sortBy,
		 isSortDirDesc,
		 refInvoiceListTable,
		 statusFilter,
		 onPayment,
		 pay,
		 formRef,
		 convertCurrency,
		 onEditPay,
		 resultPaginated,
		 statusOptions,
		 onSidebar,
		 getPay,
		 getDate,
		 allPayments,
		 avatarText,
		 resolveInvoiceStatusVariantAndIcon,
		 resolveClientAvatarVariant,
	  }
   },
}
</script>

<style lang="scss"
	   scoped>
.per-page-selector {
   width: 90px;
}


.invoice-filter-select {
   min-width: 190px;

   ::v-deep .vs__selected-options {
	  flex-wrap: nowrap;
   }

   ::v-deep .vs__selected {
	  width: 100px;
   }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>


