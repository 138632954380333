<!--NOTE: πρέπει να γίνει global component, θα το έχουμε κ σε άλλα σημεία στο app -->

<template>
   <b-sidebar
	   id="add-new-service"
	   :visible="false"
	   backdrop
	   bg-variant="white"
	   no-header
	   right
	   shadow
	   sidebar-class="sidebar-lg"
   >
	  <template #default="{ hide }">
		 <div
			 class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
		 >
			<h5 class="mb-0">{{ $t("projects.add-service") }}</h5>
			<feather-icon class="ml-1 cursor-pointer"
						  icon="XIcon"
						  size="16" />
		 </div>

		 <b-form class="p-2">
			<b-form-group label="Υπηρεσία"
						  label-for="service-title">
			   <b-form-input id="service-title"
							 placeholder=""
							 trim />
			</b-form-group>

			<b-form-group label="Τιμή"
						  label-for="service-ammount">
			   <b-form-input id="service-ammount"
							 placeholder="0.00,00"
							 trim />
			</b-form-group>

			<b-form-group class="mt-2">
			   <b-form-checkbox v-model="selected"
								value="Yes">
				  {{ $t("projects.save-to-services") }}
			   </b-form-checkbox>
			</b-form-group>

			<!-- start::form actions -->
			<div class="d-flex mt-4">
			   <b-button class="mr-2"
						 type="submit"
						 variant="primary"
						 @click="hide">
				  Προσθήκη
			   </b-button>
			   <b-button type="reset"
						 variant="outline-secondary"
						 @click="hide">
				  Ακύρωση
			   </b-button>
			</div>
			<!-- end::form actions -->
		 </b-form>
	  </template>
   </b-sidebar>
</template>

<script>
import {
   BButton,
   BForm,
   BFormCheckbox,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BSidebar,
} from "bootstrap-vue";

export default {
   components: {
	  BSidebar,
	  BForm,
	  BFormGroup,
	  BFormInput,
	  BFormInvalidFeedback,
	  BFormCheckbox,
	  BButton,
   },
};
</script>

<style lang="scss">
</style>